import React from 'react';
import { bool } from 'prop-types';
import ExclusiveFilterPopUp from './ExclusiveFilterPlain';

import ExclusiveFilterPlain from './ExclusiveFilterPlain';

const ExclusiveFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <ExclusiveFilterPopUp {...rest} /> : <ExclusiveFilterPlain {...rest} />;
};

ExclusiveFilter.defaultProps = {
  showAsPopup: false,
};

ExclusiveFilter.propTypes = {
  showAsPopup: bool,
};

export default ExclusiveFilter;
