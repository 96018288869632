import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import FilterForm from '../FilterForm/FilterForm';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './ExclusiveFilterPlain.module.css';
import { injectIntl } from 'react-intl';
import FieldToggle from '../../../components/FieldToggle/FieldToggle';
import StarDoubleIcon from '../../../components/StarDoubleIcon/StarDoubleIcon';

class ExclusiveFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    const { onSubmit } = this.props;
    onSubmit({ pub_isExclusive: !!values.pub_isExclusive ? true : null });
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      id,
      contentPlacementOffset,
      keepDirtyOnReinitialize,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>{label}</span>
              </span>
              <span className={css.openSign}>
                <div
                  className={classNames(
                    { [css.chevron]: !this.state.isOpen },
                    { [css.chevronOpen]: this.state.isOpen }
                  )}
                ></div>
              </span>
            </span>
          </button>
        </div>
        <div className={css.filterContainer}>
          <StarDoubleIcon className={css.StarIcon} />
          <div>
            <FormattedMessage className={css.mainTxt} id={'ExclusiveFilter.labelForFilter'} />
          </div>
          <FilterForm
            id={`${id}.form`}
            liveEdit
            contentPlacementOffset={contentPlacementOffset}
            onChange={this.handleChange}
            // initialValues={initialValues}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          >
            <FieldToggle
              className={css.toggle}
              id="exclusiveFilterToggle"
              name={'pub_isExclusive'}
              label={'isExclusiveFilter'}
              value={'pub_isExclusive'}
            />
          </FilterForm>
        </div>
      </div>
    );
  }
}

ExclusiveFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

ExclusiveFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default injectIntl(ExclusiveFilterPlain);
