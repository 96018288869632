import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './FieldToggle.module.scss';
import classNames from 'classnames';
import { Field } from 'react-final-form';

function FieldToggle(props) {
  const { id, value, ...rest } = props;

  const [checked, setChecked] = useState();

  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };
  return (
    <div className={classNames(css.root, { [css.rootChecked]: checked }, props.className)}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          setChecked(input.checked);
          return (
            <input
              id={id}
              className={css.checkBox}
              {...input}
              onChange={event => handleOnChange(input, event)}
              value={value}
            />
          );
        }}
      </Field>
      <label
        className={classNames(
          css.circle,
          { [css.circleTranslateBack]: !checked },
          { [css.circleChecked]: checked }
        )}
        htmlFor={id}
      ></label>
    </div>
  );
}

FieldToggle.propTypes = {};

export default FieldToggle;
