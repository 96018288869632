import React from 'react';
import PropTypes from 'prop-types';
import css from './StarDoubleIcon.module.scss';
import classNames from 'classnames';

function StarDoubleIcon(props) {
  return (
    <div className={classNames(css.root, props.className)}>
      <div className={css.starOne}>
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none">
          <defs>
            <linearGradient
              id="paint0_linear_1053_45197"
              x1="-1.34375"
              y1="-1.16406"
              x2="19.0163"
              y2="-3.80898"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#632DFF" />
              <stop offset="1" stopColor="#8052FF" />
            </linearGradient>
          </defs>
          <path
            d="M8.34697 1.43235C9.38085 0.262044 11.2334 0.262044 12.2673 1.43235C12.9269 2.17894 13.9173 2.56279 14.9085 2.44582L15.6125 2.36273C16.9329 2.20691 18.0368 3.3603 17.8263 4.67433C17.6703 5.64821 18.1021 6.62474 18.9291 7.16298C20.1388 7.95026 20.1388 9.72161 18.9291 10.5089C18.1021 11.0471 17.6703 12.0237 17.8263 12.9975C18.0368 14.3116 16.9329 15.465 15.6125 15.3091L14.9085 15.2261C13.9173 15.1091 12.9269 15.4929 12.2673 16.2395C11.2334 17.4098 9.38085 17.4098 8.34697 16.2395C7.68741 15.4929 6.69694 15.1091 5.7058 15.2261L5.00172 15.3091C3.68132 15.465 2.57748 14.3116 2.78798 12.9975C2.94398 12.0237 2.51218 11.0471 1.68515 10.5089C0.47545 9.72161 0.47545 7.95026 1.68515 7.16298C2.51218 6.62474 2.94398 5.64821 2.78798 4.67433C2.57748 3.3603 3.68132 2.20691 5.00172 2.36273L5.7058 2.44582C6.69694 2.56279 7.68741 2.17894 8.34697 1.43235Z"
            fill="url(#paint0_linear_1053_45197)"
            stroke="white"
            strokeWidth="0.5"
          />
        </svg>
        <div className={css.check}>
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
            <path
              d="M11.8881 0.474247C12.2132 0.753264 12.2132 1.20416 11.8881 1.48318L5.23046 7.19746C4.90538 7.47648 4.38005 7.47648 4.05497 7.19746L0.725422 4.34032C0.400445 4.0613 0.400445 3.61041 0.725422 3.33139C1.05045 3.05237 1.57734 3.05237 1.90242 3.33139L4.61931 5.68184L10.7126 0.474247C11.0377 0.194782 11.563 0.194782 11.8881 0.474247Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className={css.starTwo}>
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none">
          <path
            d="M8.34697 1.43235C9.38085 0.262044 11.2334 0.262044 12.2673 1.43235C12.9269 2.17894 13.9173 2.56279 14.9085 2.44582L15.6125 2.36273C16.9329 2.20691 18.0368 3.3603 17.8263 4.67433C17.6703 5.64821 18.1021 6.62474 18.9291 7.16298C20.1388 7.95026 20.1388 9.72161 18.9291 10.5089C18.1021 11.0471 17.6703 12.0237 17.8263 12.9975C18.0368 14.3116 16.9329 15.465 15.6125 15.3091L14.9085 15.2261C13.9173 15.1091 12.9269 15.4929 12.2673 16.2395C11.2334 17.4098 9.38085 17.4098 8.34697 16.2395C7.68741 15.4929 6.69694 15.1091 5.7058 15.2261L5.00172 15.3091C3.68132 15.465 2.57748 14.3116 2.78798 12.9975C2.94398 12.0237 2.51218 11.0471 1.68515 10.5089C0.47545 9.72161 0.47545 7.95026 1.68515 7.16298C2.51218 6.62474 2.94398 5.64821 2.78798 4.67433C2.57748 3.3603 3.68132 2.20691 5.00172 2.36273L5.7058 2.44582C6.69694 2.56279 7.68741 2.17894 8.34697 1.43235Z"
            fill="#FAF9FE"
            stroke="#BA9EFF"
            strokeWidth="0.5"
          />
        </svg>
        <div className={css.check}>
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
            <path
              d="M11.8881 0.474247C12.2132 0.753264 12.2132 1.20416 11.8881 1.48318L5.23046 7.19746C4.90538 7.47648 4.38005 7.47648 4.05497 7.19746L0.725422 4.34032C0.400445 4.0613 0.400445 3.61041 0.725422 3.33139C1.05045 3.05237 1.57734 3.05237 1.90242 3.33139L4.61931 5.68184L10.7126 0.474247C11.0377 0.194782 11.563 0.194782 11.8881 0.474247Z"
              fill="#9B73FF"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

StarDoubleIcon.propTypes = {};

export default StarDoubleIcon;
